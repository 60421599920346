import React from "react"
import { Code } from "@atlaskit/code"
import ApiResponseCodeBlock from "../components/ApiResponseCode/ApiResponseCodeBlock"
const styles = require("./Pages.module.scss");

export default function Pagination() {
  return (
    <React.Fragment>
      <div className={styles.Content}>
        <h1>Pagination</h1>

        <p>All of the list API endpoints, such as <Code text="GET /jobs" language="javascript" /> will include information about the pagination in the response data.</p>

        <p> List API endpoints return up to 50 results per page.</p>

        <p>You can fetch the next page of results by including a query string parameter <Code text="?page=x" language="javascript" /> with <Code text="x" language="javascript" /> being the page you want to get next.</p>

        <p>Paginated responses all return the data structure seen below.</p>

        <ApiResponseCodeBlock response={{
          "status": "ok",
          "code": 200,
          "data": {
            "pagination": {
              "currentPage": 1,
              "from": 1,
              "lastPage": 3,
              "perPage": 50,
              "hasMore": true,
              "nextPage": 2,
              "nextPageUrl": "https://us-01.api.prestavi.com/users?page=2",
              "to": 50,
              "total": 140
            }
          }
        }} />
      </div>
    </React.Fragment>

  )
}